import React from 'react'
import Header from '../components/Header'
import PracticeImage from '../assest/practicedetail.png'
import Icon from '../assest/teamsicon.svg'

const PracticeDetail = () => {
    return (
        <div className="w-full flex flex-col bg-[#1F2732]">
            <div className="w-full flex flex-col">
                <div className="w-full flex flex-col bg-background-icsayfa bg-[#1F2732] justify-center items-center">
                    <Header />
                    <div className="w-full flex flex-col justify-center items-center gap-5 py-10  lg:py-20">
                        <div className="font-baslik text-4xl text-white">Practice Area</div>
                        <img src={Icon} alt="" />
                    </div>
                </div>
                <div className="w-full flex flex-col gap-7 justify-center items-center pb-10 lg:pb-20">
                    <div className="lg:w-4/5 flex justify-center items-center lg:y-20">
                        <img src={PracticeImage} alt="" />
                    </div>
                    <div className="w-4/5 flex flex-col justify-start items-start gap-10 text-white">
                        <div className="flex flex-col gap-5">
                            <div className="font-baslik text-4xl">Introduction</div>
                            <div className="text-[#D7D7D7] font-outfit">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt architecto sed, incidunt possimus magnam hic consequuntur
                                cumque voluptatibus ullam, commodi est tempora dolorem perferendis harum earum tempore quia reiciendis quaerat.
                            </div>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="font-baslik text-4xl">About the law</div>
                            <div className="text-[#D7D7D7] font-outfit">
                                Appropriately empower dynamic leadership skills after business portals. Globally my cardinate interactive supply chains with
                                distinctive quality vectors global sources services. Uniquely matrix economically sound value through cooperative technology.
                                Competently parallel task fully researched data and enterprise process improvements.
                            </div>
                            <ul className="flex flex-col gap-3 list-disc text-[#D7D7D7] font-outfit">
                                <li>Dynamically target high-payoff intellectual capital for customized</li>
                                <li>Interactively procrastinate high-payoff content</li>
                                <li>Credibly reinter mediate backend ideas for cross-platform models</li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="font-baslik text-4xl">Make real time a law services</div>
                            <div className="text-[#D7D7D7] font-outfit">
                                Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed
                                base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.
                            </div>
                            <ul className="flex flex-col gap-3 list-decimal text-[#D7D7D7] font-outfit">
                                <li>It brings the right people together with all the right information and tools to get work done</li>
                                <li>We provide operational efficiency, data security, and flexible scale</li>
                                <li>Your best work, together in one package that works seamlessly from your computer</li>
                                <li>Delivers the tools you need to save time Improve field performance always</li>
                            </ul>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="font-baslik text-4xl">Overview:</div>
                            <div className="text-[#D7D7D7] font-outfit">
                                Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new
                                normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content
                                in real-time will have multiple touchpoints for offshoring interoperable internal or “organic” sources.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracticeDetail
