import React from 'react'
import Left from '../assest/footerleft.svg'
import Logo from '../assest/Icon.svg'
import Right from '../assest/footerright.svg'
import Insta from '../assest/Insta.svg'
import Facebook from '../assest/Fb.svg'
import Twitter from '../assest/Twiter.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="w-4/5 border-t border-[#4E555F] flex flex-col bg-[#1F2732] justify-center items-center">
           
            <div className="w-1/2 flex flex-col lg:flex-row py-10 gap-20">
                <div className="text-[#D7D7D7] font-outfit flex lg:flex lg:flex-col w-full lg:gap-3 gap-7">
                    <Link to={'/'}>Anasayfa</Link>
                </div>
                <div className="text-[#D7D7D7] font-outfit hidden lg:flex lg:flex-col w-full gap-3">
                    <Link to={'/hakkimizda'}>Hakkımızda</Link>
                </div>
                <div className="text-[#D7D7D7] font-outfit hidden lg:flex lg:flex-col w-full gap-3">
                    <Link to={'/iletisim'}>İletişim</Link>
                </div>
                <div className="text-[#D7D7D7] font-outfit hidden lg:flex lg:flex-col w-full gap-3">
                    <Link to={'/blog'}>Blog</Link>
                </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row border border-x-0 border-[#4E555F] py-6 text-center lg:text-left gap-5 lg:gap-0">
                <span className="w-full flex flex-col lg:flex-row gap-2 text-[#D7D7D7] font-outfit">
                    2024 © Tüm hakları saklıdır. | Developed by <div className="text-[#D1B06B]"><Link to={'https://poyrates.com'} target={'_blank'}>Poyrates</Link></div> 
                </span>
                <div className="flex flex-row gap-4 items-center w-3/4 justify-end font-outfit">

                    <div className="flex flex-row gap-4">
                        <a href="#">
                            <img src={Insta} alt="" />
                        </a>
                        <a href="#">
                            <img src={Facebook} alt="" />
                        </a>
                        <a href="#">
                            <img src={Twitter} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
