import React from 'react'
import Header from '../components/Header'
import Icon from '../assest/teamsicon.svg'
import Blogone from '../assest/blogone.png'
import Blogtwo from '../assest/blogtwo.png'
import { Link } from 'react-router-dom'
import Practice from '../components/Practice'
import Contact from '../components/Contact'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'

const ResearchPage = () => {

    return (
<>
    <div className="w-full flex flex-col">
        <div className="w-full flex flex-col">
            <div className="w-full flex flex-col bg-background-icsayfa bg-[#1F2732] justify-center items-center">
                <Header />
                <div className="w-full flex flex-col justify-center items-center gap-5 py-20">
                    <div className="font-baslik text-4xl text-white">Araştırmalar</div>
                    <img src={Icon} alt="" />
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
            <table className="flex container text-left table-fixed my-14">
    <tbody>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-semibold'>ARAŞTIRMA TALEP EDİLEN DAL</td>
            <td className='p-3 border-b-2 font-semibold'>ALANI</td>
            <td className='p-3 border-b-2 font-semibold'>HAZIRLANACAK İÇERİK</td>
            <td className='p-3 border-b-2 font-semibold'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>AİLE HUKUKU</td>
            <td className='p-3 border-b-2 font-light'>BOŞANMA</td>
            <td className='p-3 border-b-2 font-light'>DELİL DOSYALARI</td>
            <td className='p-3 border-b-2 font-light'>TARAFLARA AİT HUKUKİ TESPİT ARAŞTIRMALARI</td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>VELAYET</td>
            <td className='p-3 border-b-2 font-light'>KARŞI TARAF UYGUNLUK TESPİT İNCELEMESİ</td>
            <td className='p-3 border-b-2 font-light'></td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>TARAF FİNANSAL DURUM ARAŞTIRMALARI</td>
            <td className='p-3 border-b-2 font-light'>MAL REJİMİ </td>
            <td className='p-3 border-b-2 font-light'>MUVAZAALI DURUM TESPİT İNCELEMELERİ</td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>MİRAS HUKUKU</td>
            <td className='p-3 border-b-2 font-light'>MAL REJİMİ </td>
            <td className='p-3 border-b-2 font-light'>HAZIRLANACAK İÇERİK</td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>KAMULAŞTIRMA</td>
            <td className='p-3 border-b-2 font-light'></td>
            <td className='p-3 border-b-2 font-light'></td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>BORÇLAR HUKUKU</td>
            <td className='p-3 border-b-2 font-light'>BORÇLU DURUMU</td>
            <td className='p-3 border-b-2 font-light'>FİNANSAL DURUM ARAŞTIRMALARI</td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'></td>
            <td className='p-3 border-b-2 font-light'>MÜLK DURUMU</td>
            <td className='p-3 border-b-2 font-light'>MÜLKİYETE ESAS RESMİ TESPİT SORUŞTURMALARI</td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>ŞİRKETLER</td>
            <td className='p-3 border-b-2 font-light'>MALİ İNCELEMELER</td>
            <td className='p-3 border-b-2 font-light'>HUKUKSUZLUK TESPİT SORUŞTURMALARI</td>
            <td className='p-3 border-b-2 font-light'></td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>TİCARET HUKUKU</td>
            <td className='p-3 border-b-2 font-light'>ANONİM ŞİRKETLER</td>
            <td className='p-3 border-b-2 font-light'>GENEL KURUL İŞLEMLERİNE YÖNELİK SORUŞTURMALAR</td>
            <td className='p-3 border-b-2 font-light'>AZINLIK HAKLARI İÇİN KORUYUCU ARAŞTIRMALAR</td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'></td>
            <td className='p-3 border-b-2 font-light'>HALKA AÇIK ANONİM ŞİRKETLER</td>
            <td className='p-3 border-b-2 font-light'>YÖNETİM KURULU İŞLEMLERİNE YÖNELİK İNCELEME, SORUŞTURMA VE ARAŞTIRMALAR</td>
            <td className='p-3 border-b-2 font-light'>HALKA AÇIK PAY SAHİPLERİNİN KORUNMASI</td>
        </th>
        <th className='table-row'>
            <td className='p-3 border-b-2 font-light'>ÇALIŞMA HUKUKU</td>
            <td className='p-3 border-b-2 font-light'>ÇALIŞAN İLİŞKİLERİ</td>
            <td className='p-3 border-b-2 font-light'>İNCELEME RAPORLARI</td>
            <td className='p-3 border-b-2 font-light'>ÖN TAHMİN RAPORLARI</td>
        </th>
</tbody>		
</table>
                <div className="w-full flex bg-[#1F2732] justify-center items-center">
                    <Contact />
                </div>
                <ContactForm />
            </div>
        </div>
    </div>
    <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
        <Footer /> 
    </div>
</>
    )
}

export default ResearchPage

	