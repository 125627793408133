import React from 'react'
import Header from '../components/Header'
import Icon from '../assest/teamsicon.svg'
import Blogone from '../assest/blogone.png'
import Blogtwo from '../assest/blogtwo.png'
import { Link } from 'react-router-dom'
import Practice from '../components/Practice'
import Contact from '../components/Contact'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'

const AcademicPages = () => {
    const servicesArray = {
        subDivision:[ 
            {
                subAreaTitle: "MAKALELER"
            },
            {
                subAreaTitle: "MEVZUAT"
            }
        ],
        
    }
    return (
<>
    <div className="w-full flex flex-col">
        <div className="w-full flex flex-col">
            <div className="w-full flex flex-col bg-background-icsayfa bg-[#1F2732] justify-center items-center">
                <Header />
                <div className="w-full flex flex-col justify-center items-center gap-5 py-20">
                    <div className="font-baslik text-4xl text-white">Akademik Öneriler</div>
                    <img src={Icon} alt="" />
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center">
                <Practice data={servicesArray} />
                <div className="w-full flex bg-[#1F2732] justify-center items-center">
                    <Contact />
                </div>
                <ContactForm />
            </div>
        </div>
    </div>
    <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
        <Footer /> 
    </div>
</>
    )
}

export default AcademicPages
