import React, { useState } from 'react'
import Logo from '../assest/Icon.svg'
import Mail from '../assest/mail.svg'
import Phone from '../assest/phone.svg'
import { Link } from 'react-router-dom'
import Linkedin from '../assest/LinkedIn_icon.svg'
import Facebook from '../assest/Fb.svg'
import Twitter from '../assest/Twiter.svg'


const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen)
    }
    return (
        <div className="flex container pt-[10px] w-full justify-center items-center flex-row gap-4 border border-x-0 border-[#c3cad4] border-spacing-1 py-1 self-center">
            <div className="w-2/5 flex flex-row items-center gap-4 ">
                <a href='/home'>
                <img className="md:flex hidden" src={Logo} alt="logo" />
                </a>
            </div>
            <div className="w-3/5 hidden md:flex flex-row ">
                <div className="w-3/4 flex gap-10 text-l font-normal text-white items-center justify-end font-outfit">
                    <Link to={'/calisma-alanlari'}>Çalışma Alanları</Link>
                    <Link to={'/hizmetler'}>Hizmetler</Link>
                    <Link to={'/akademik-oneriler'}>Akademik Öneriler</Link>
                    <Link to={'/arastirmalar'}>Araştırmalar</Link>
                    <Link to={'/iletisim'}>İletişim</Link>
                </div>
                <div className="flex flex-row gap-4 items-center w-1/4 justify-end font-outfit">
                    <div className="flex flex-row gap-4">
                        <a href="#">
                            <img src={Linkedin} alt="" />
                        </a>
                        <a href="#">
                            <img src={Facebook} alt="" />
                        </a>
                        <a href="#">
                            <img src={Twitter} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
