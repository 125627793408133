import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Aboutus from '../components/Aboutus'
import Contact from '../components/Contact'
import ContactForm from '../components/ContactForm'
import Practice from '../components/Practice'
import Teams from '../components/Teams'
import Blog from '../components/Blog'

const HomePage: React.FC = () => {
    const praticeArray = {
        divisionTitle: "Çalıştığımız Hukuk Alanları",
        subDivision:[ 
            {
                subAreaTitle: "SERMAYE PİYASALARI ve MENKUL DEĞERLER", 
                subAreaSubTitle01: "MENKUL DEĞER İŞLEMLERİ İLE İLGİLİ SORUNLAR", 
                subAreaSubTitle02: "PYŞ KURULUMU", 
                subAreaSubTitle03: "ARACI KURUM OLUŞUMLARI", 

            },
            {
                subAreaTitle: "SÖZLEŞMELER", 
                subAreaSubTitle01: "ÇALIŞMA SÖLEŞMELERİ", 
                subAreaSubTitle02: "TİCARİ SÖZLEŞMELER",
                subAreaSubTitle03: "ULUSLARARASI TİCARİ SÖZLEŞMELER", 
                subAreaSubTitle04: "TAŞIMA SÖZLEŞMELERİ",
            },
            {
                subAreaTitle: "ŞİRKETLER HUKUKU", 
                subAreaSubTitle01: "YAPISAL ŞİRKET DÖNÜŞÜMLERİ", 
            },
            {
                subAreaTitle: "TİCARET HUKUKU", 
                subAreaSubTitle01: "İŞVEREN-ÇALIŞAN HUKUKİ SİSTEMLERİ", 
                subAreaSubTitle02: "İŞVEREN-ÇALIŞAN SORUNLARI", 
            },{ 
                subAreaTitle: "TİCARİ İŞLETMELER HUKUKU",
                subAreaSubTitle01: "HUKUKİ KURUMSALLAŞMA", 
                subAreaSubTitle02: "KURUMSALLAŞMA SİSTEMLERİ KURULUMU", 
                subAreaSubTitle03: "MARKA/PATENT", 
            }
        ]
    }
    const servicesArray = {
        divisionTitle: "Hizmetlerimiz",
        subDivision:[ 
            {
                subAreaTitle: "HUKUKİ KURUMSALLAŞMA"
            },{
                subAreaTitle: "SERMAYE PİYASALARINDA KORUNMAK"
            },{ 
                subAreaTitle: "SÖZLEŞMELER"
            },{
                subAreaTitle: "MODERN BELGELENDİRME SİSTEMLERİNİN HUKUKİ KORUNMAYA KATKILARI", 
            }
        ]
    }
    return (
<>
                <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
                    <div className="flex w-full  bg-cover bg-no-repeat flex-col">
                        <Header />
                        <Banner />
                    </div>
                    <Aboutus />
                    <Practice data={praticeArray} />
                    <Contact />
                    <Practice data={servicesArray} />
                    {/* <Teams /> */}
                    <ContactForm />
                    <Blog />
                </div>
                <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
                   <Footer /> 
                </div>
</>
    )
}

export default HomePage
