import React from 'react'
import Header from '../components/Header'
import Practice from '../components/Practice'
import Icon from '../assest/teamsicon.svg'
import Contact from '../components/Contact'
import ContactForm from '../components/ContactForm'
import Footer from '../components/Footer'

const PracticeArea = () => {
    const praticeArray = {
        
        subDivision:[ 
            {
                subAreaTitle: "SERMAYE PİYASALARI, MENKUL DEĞERLER", 
                subAreaSubTitle01: "MENKUL DEĞER İŞLEMLERİ İLE İLGİLİ SORUNLAR", 
                subAreaSubTitle02: "PYŞ KURULUMU", 
                subAreaSubTitle03: "ARACI KURUM OLUŞUMLARI", 

            },
            {
                subAreaTitle: "SÖZLEŞMELER", 
                subAreaSubTitle01: "ÇALIŞMA SÖLEŞMELERİ", 
                subAreaSubTitle02: "TİCARİ SÖZLEŞMELER",
                subAreaSubTitle03: "ULUSLARARASI TİCARİ SÖZLEŞMELER", 
                subAreaSubTitle04: "TAŞIMA SÖZLEŞMELERİ",
            },
            {
                subAreaTitle: "ŞİRKETLER HUKUKU", 
                subAreaSubTitle01: "YAPISAL ŞİRKET DÖNÜŞÜMLERİ", 
            },
            {
                subAreaTitle: "TİCARET HUKUKU", 
                subAreaSubTitle01: "İŞVEREN-ÇALIŞAN HUKUKİ SİSTEMLERİ", 
                subAreaSubTitle02: "İŞVEREN-ÇALIŞAN SORUNLARI", 
            },{ 
                subAreaTitle: "TİCARİ İŞLETMELER HUKUKU",
                subAreaSubTitle01: "HUKUKİ KURUMSALLAŞMA", 
                subAreaSubTitle02: "KURUMSALLAŞMA SİSTEMLERİ KURULUMU", 
                subAreaSubTitle03: "MARKA/PATENT", 
            }
        ]

    }
    return (
        <>
        <div className="w-full flex flex-col">
            <div className="w-full flex flex-col">
                <div className="w-full flex flex-col bg-background-icsayfa bg-[#1F2732] justify-center items-center">
                    <Header />
                    <div className="w-full flex flex-col justify-center items-center gap-5 py-20">
                        <div className="font-baslik text-4xl text-white">Çalışma Alanlarımız</div>
                        <img src={Icon} alt="" />
                    </div>
                </div>
                <div className="w-full flex flex-col justify-center items-center">
                    <Practice data={praticeArray} />
                    <div className="w-full flex bg-[#1F2732] justify-center items-center">
                        <Contact />
                    </div>
                    <ContactForm />
                </div>
            </div>
        </div>
        <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
            <Footer /> 
        </div>
        </>
    )
}

export default PracticeArea
