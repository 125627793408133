import React from 'react'
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom'
import Home from './pages/HomePage'
import Under from './pages/Under'
import About from './pages/ServicesPage'
import Contact from './pages/ContactPage'
import Header from './components/Header'
import BlogPages from './pages/BlogPages'
import Footer from './components/Footer'
import PracticeArea from './pages/PracticePage'
import PracticeDetail from './pages/PracticeDetail'
import BlogDetail from './pages/BlogDetail'
import ServicesPage from './pages/ServicesPage'
import AcademicPages from './pages/AcademicPage'
import './index.css'
import ResearchPage from './pages/ResearchPage'


const App = () => {
    return (
        <>
            <Router>
                <div>
                    <Routes>
                        <Route path="/hakkimizda" element={<About />} />
                        <Route path="/iletisim" element={<Contact />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/akademik-oneriler" element={<AcademicPages />} />
                        <Route path="/calisma-alanlari" element={<PracticeArea />} />
                        <Route path="/hizmetler" element={<ServicesPage />} />
                        <Route path="/arastirmalar" element={<ResearchPage />} />
                        <Route path="/practicedetail" element={<PracticeDetail />} />
                        <Route path="/blogdetail" element={<BlogDetail />} />
                    </Routes>
                </div>
                <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
                   {/* <Footer /> */}
                </div>
            </Router>
        </>
    )
}

export default App
