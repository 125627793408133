import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import BlogDetaill from '../assest/blogdetail.png'
import Person from '../assest/blogdetailperson.png'
import Facebook from '../assest/blackfb.svg'
import Insta from '../assest/blackinsta.svg'
import Twitter from '../assest/blacktw.svg'

const BlogDetail = () => {
    return (
        <div>
            <div>
                <div className="bg-[#1F2732]">
                    <Header />
                </div>
                <div className="w-full justify-center items-center flex flex-col bg-[#1F2732] py-10 lg:py-20">
                    <div className="lg:w-4/5 flex flex-col justify-center items-center">
                        <div>
                            <img src={BlogDetaill} alt="" />
                        </div>
                        <div className="w-4/5 lg:w-2/3 flex flex-col justify-start items-start py-8 lg:py-16 gap-5 lg:gap-10">
                            <div className="w-full flex flex-col gap-10">
                                <div className="text-[#D1B06B] font-outfit">Legal Advice | Sunday, July 19, 2022 </div>
                                <div className="font-baslik text-3xl text-white">Failing to Carry Out The Terms of A Business Contract</div>
                            </div>
                            <div className="w-full flex flex-col gap-10">
                                <div className="w-full border border-[#3A4553]"></div>
                                <div className="text-[#D7D7D7] font-outfit text-sm">
                                    Uniquely matrix economically sound value through cooperative technology. Competently parallel task fully researched data and
                                    enterprise process improvements. Collaboratively expedite quality manufactured products via client-focused results quickly
                                    communicate enabled technology and turnkey leadership skills. Uniquely enable accurate supply chains rather than friction
                                    technology.
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-5">
                                <div className="font-baslik text-3xl text-white">Equal Justice for all Livings</div>
                                <div className="text-[#D7D7D7] font-outfit text-sm">
                                    Appropriately empower dynamic leadership skills after business portals. Globally my cardinate interactive supply chains with
                                    distinctive quality vectors global sources services. Uniquely matrix economically sound value through cooperative
                                    technology. Competently parallel task fully researched data and enterprise process improvements.
                                </div>
                                <ul className="list-disc text-sm flex flex-col gap-3 text-[#D7D7D7] font-outfit">
                                    <li>Dynamically target high-payoff intellectual capital for customized</li>
                                    <li>Interactively procrastinate high-payoff content</li>
                                    <li>Credibly reinter mediate backend ideas for cross-platform models</li>
                                </ul>
                            </div>
                            <div className="w-full bg-[#D1B06B] p-8 lg:p-16 font-baslik text-xl flex justify-center items-center text-center text-[#1F2732]">
                                “Democracy must be built through open societies that share information. When there is information, there is enlightenment. When
                                there is no sharing of power, no rule of law, no accountability, there is abuse, corruption, subjugation and indignation.”
                            </div>
                            <div className="w-full flex flex-col gap-5">
                                <div className="font-baslik text-3xl text-white">Make real time a law services</div>
                                <div className="text-[#D7D7D7] font-outfit text-sm">
                                    Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after
                                    installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.
                                </div>
                                <ul className=" list-decimal text-[#D7D7D7] font-outfit text-sm flex flex-col gap-3">
                                    <li>It brings the right people together with all the right information and tools to get work done</li>
                                    <li>We provide operational efficiency, data security, and flexible scale</li>
                                    <li>Your best work, together in one package that works seamlessly from your computer</li>
                                    <li>Delivers the tools you need to save time Improve field performance always</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="full flex flex-col lg:flex-row justify-center items-center py-10 lg:py-20">
                    <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-5 lg:gap-10">
                        <img src={Person} alt="" />
                        <div className="w-1/2 flex flex-col justify-start items-start gap-4 lg:gap-7">
                            <div className="font-baslik text-3xl">John Carter</div>
                            <div className="text-sm text-[#909090] font-outfit">
                                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The
                                point of using Lorem Ipsum is that it has readable English.{' '}
                            </div>
                            <div className="flex flex-row gap-5">
                                <a href="#">
                                    <img src={Facebook} alt="" />
                                </a>
                                <a href="#">
                                    <img src={Insta} alt="" />
                                </a>
                                <a href="#">
                                    <img src={Twitter} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogDetail
