import React from 'react'
import Background from '../assest/formbg.png'

const ContactForm = () => {
    return (
        <div className="bg-background-contact w-full flex justify-center items-center py-10 lg:py-28">
            
            <div className="w-full lg:w-full flex  justify-center items-center">
                    <form className="w-4/5 flex gap-20 justify-center items-start flex-col lg:flex-row bg-[#161D27] py-20 font-outfit text-[#D7D7D7]" action="#" method="post">
                        <div className="lg:w-1/3 flex flex-col gap-10 justify-center items-center">
                            <input
                                className="w-full placeholder-[#D7D7D7] bg-transparent border-b border-[#3A4553] p-3 text-[#D7D7D7]"
                                placeholder="İsim Soyisim"
                                type="text"
                                id="isimSoyisim"
                                name="isimSoyisim"
                            />
                            <input
                                className="w-full placeholder-[#D7D7D7] bg-transparent border-b border-[#3A4553] p-3 text-[#D7D7D7]"
                                placeholder="Mail Adresi"
                                type="text"
                                id="mailadresi"
                                name="mailadresi"
                            />
                            <input
                                className="w-full placeholder-[#D7D7D7] bg-transparent border-b border-[#3A4553] p-3 text-[#D7D7D7]"
                                placeholder="Telefon Numarası"
                                type="text"
                                id="telefonno"
                                name="telefonno"
                            />
                        </div>
                        <div className="lg:w-1/2 flex flex-col gap-10">
                            <select className="w-full placeholder-[#D7D7D7] bg-transparent border-b border-[#3A4553] p-3 text-[#D7D7D7]" id="serviceSelect" name="serviceSelect">
                                <option selected>Hizmet Almak İstediğiniz Konu Nedir?</option>
                                <option>SERMAYE PİYASALARI, MENKUL DEĞERLER</option>
                                <option>MENKUL DEĞER İŞLEMLERİ İLE İLGİLİ SORUNLAR</option>
                                <option>PYŞ KURULUMU</option>
                                <option>ARACI KURUM OLUŞUMLARI</option>
                                <option>SÖZLEŞMELER</option>
                                <option>ÇALIŞMA SÖLEŞMELERİ</option>
                                <option>TİCARİ SÖZLEŞMELER</option>
                                <option>ULUSLARARASI TİCARİ SÖZLEŞMELER</option>
                                <option>TAŞIMA SÖZLEŞMELERİ</option>
                                <option>ŞİRKETLER HUKUKU</option>
                                <option>YAPISAL ŞİRKET DÖNÜŞÜMLERİ</option>
                                <option>TİCARET HUKUKU</option>
                                <option>İŞVEREN-ÇALIŞAN HUKUKİ SİSTEMLERİ</option>
                                <option>İŞVEREN-ÇALIŞAN SORUNLARI</option>
                                <option>TİCARİ İŞLETMELER HUKUKU</option>
                            </select>
                            <textarea
                                className="w-full placeholder-[#D7D7D7] bg-[#161D27] border border-[#D1B06B] h-[130px] p-5 text-[#D7D7D7]"
                                placeholder="Mesajınız"
                                id="mesaj"
                                name="mesaj"
                            ></textarea>
                            <button className="lg:w-1/3 bg-[#D1B06B] p-2 text-[#1F2732] self-end justify-end">Mesajı Gönder</button>
                        </div>
                        
                    </form>
            </div>
        </div>
    )
}

export default ContactForm
