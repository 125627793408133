import React from 'react'
import Phone from '../assest/phone1.svg'

const Contact = () => {
    return (
        <div className="w-4/5 flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20 py-10 lg:py-28">
            <div className="md:w-2/5 flex flex-col gap-7">
                <div className=" font-baslik text-3xl text-white">Akademik destekli ekibimiz, bilgi talepleri, danışmanlık ve hukuki yönlendirmelerde yardımcı olmaktadır.</div>
                <div className=" text-[#D7D7D7] font-outfit">

                </div>
                <a href="/iletisim">
                    <button className="w-[220px] h-[70px] bg-[#D1B06B] font-outfit text-xl">İletişim</button>
                </a>
            </div>
            <div className="md:w-1/2 flex flex-col bg-white">
                <div className=" border border-[#DFDFDF] m-10 p-10 flex flex-col gap-5">
                    <div className=" font-baslik text-2xl ">Dava ön görüş için randevu alınız</div>
                    <div className="text-[#909090] font-outfit">
                        
                    </div>
                    <div className="flex flex-row gap-5 items-center">
                        <div className='flex flex-row'>
                            <img src={Phone} alt="" />
                            <a href="tel:+(90) 532 290 64 55"><div className="p-[1rem] font-bold text-lg ">+(90) 532 290 64 55</div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
