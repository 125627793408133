import React from 'react'
import Icon from '../assest/teamsicon.svg'
import Blogone from '../assest/blogone.png'
import Blogtwo from '../assest/blogtwo.png'

const Blog = () => {
    return (
        <div className="w-4/5 flex flex-col justify-center items-center py-10 lg:py-28 gap-10 lg:gap-20">
            <div className="w-full flex flex-col justify-center items-center gap-5">
                <div className="font-baslik text-4xl text-white">BÜLTENLER</div>
                <div>
                    <img src={Icon} alt="" />
                </div>
            </div>
            
            <div className="w-full border border-[#3A4553]"></div>
            <div className="w-full flex items-center gap-20 relative flex-col lg:flex-row justify-between">
                <div className="lg:w-1/3 flex flex-col gap-4">
                    <img src={Blogone} alt="" />
                    <div className="text-[#D1B06B] font-outfit"></div>
                    <div className="border border-[#3A4553]"></div>
                    <div className="font-baslik text-2xl text-white">HAFTALIK SPK BÜLTENİ</div>
                    <div className="text-[#D7D7D7] font-outfit">
                    </div>
                    <a href="#" target="_blank">
                        <button className="text-[#D1B06B] font-outift text-xl border-b-2 border-[#D1B06B]">İncele</button>
                    </a>
                </div>
                <div className="lg:w-1/3 flex flex-col gap-4">
                    <img src={Blogtwo} alt="" />
                    <div className="text-[#D1B06B] font-outfit"></div>
                    <div className="border border-[#3A4553]"></div>
                    <div className="font-baslik text-2xl text-white">HAFTALIK BIST BÜLTENİ</div>
                    <div className="text-[#D7D7D7] font-outfit">
                    </div>
                    <a href="#" target="_blank">
                        <button className="text-[#D1B06B] font-outift text-xl border-b-2 border-[#D1B06B]">İncele</button>
                    </a>
                </div>
                <div className="lg:w-1/3 flex flex-col gap-4">
                    <img src={Blogone} alt="" />
                    <div className="text-[#D1B06B] font-outfit"></div>
                    <div className="border border-[#3A4553]"></div>
                    <div className="font-baslik text-2xl text-white">HAFTALIK REKABET KURUMU BÜLTENİ</div>
                    <div className="text-[#D7D7D7] font-outfit">
                    </div>
                    <a href="#" target="_blank">
                        <button className="text-[#D1B06B] font-outift text-xl border-b-2 border-[#D1B06B]">İncele</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Blog
