import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Icon from '../assest/teamsicon.svg'
import Map from '../assest/map-icon.svg'
import Mail from '../assest/mail.svg'
import Phone from '../assest/phone.svg'
import ContactForm from '../components/ContactForm'

const Contact = () => {
    return (
<>
        <div>
            <div>
                <div className="w-full flex flex-col bg-background-icsayfa bg-[#1F2732] justify-center items-center">
                    <Header />
                    <div className="w-full flex flex-col justify-center items-center gap-5 py-20">
                        <div className="font-baslik text-4xl text-white">İletişim</div>
                        <img src={Icon} alt="" />
                    </div>
                </div>
                <div className="w-full flex flex-col justify-center items-center bg-[#1F2732] pt-20 gap-20">
                    <div className="w-4/5 flex flex-col lg:flex-row justify-center items-center gap-10">
                        <div className="lg:w-1/3 flex flex-row justify-start items-center gap-5 ">
                            <img src={Map} alt="" />
                            <div>
                                <div className="text-[#CCAA60] text-lg">Adres:</div>
                                <div className="text-white text-lg">Atatürk Cad. No. 53 D.53 Rize{' '}</div>
                                <div className="text-[#b1d3fd] text-xs"><a href="https://maps.app.goo.gl/rYemXazGdJLwaVSi8" target="_blank" rel="noreferrer">Yol tarifi</a></div>
                            </div>
                        </div>
                        <div className="lg:w-1/3 flex flex-row justify-start items-center gap-5 ">
                            <img src={Mail} alt="" />
                            <div>
                                <div className="text-[#CCAA60] text-lg ">Mail Adresimiz:</div>
                                <div className="text-white text-lg"><a href="mailto:avabdulkadirerdem@gmail.com">avabdulkadirerdem@gmail.com</a></div>
                            </div>
                        </div>
                        <div className="lg:w-1/3 flex flex-row justify-start items-center gap-5 ">
                            <img src={Phone} alt="" />
                            <div>
                                <div className="text-[#CCAA60] text-lg ">Bizi Arayın:</div>
                                <div className="text-white text-lg">+(90) 532 290 64 55</div>
                            </div>
                        </div>
                    </div>
                    <ContactForm />

                </div>
            </div>
        </div>
        <div className="relative w-full flex justify-center items-center flex-col bg-[#1F2732]">
            <Footer /> 
        </div>
</>
    )
}

export default Contact
