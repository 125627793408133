import React from 'react'
import Bannericon from '../assest/bannericon.svg'
import Person from '../assest/Photo.png'

const Banner = () => {
    return (
        <div className="w-full flex justify-center lg:h-[560px] xl:h-[] 2xl:h-[800px] items-center pt-10 lg:pt-0">
            <div className="w-4/5 flex flex-row">
                <div className="w-full flex flex-row text-center lg:text-left py-5 lg:py-0">
                    <div className="flex w-full lg:w-1/2 flex-col gap-5">
                        <div>
                            <img src={Bannericon} alt="" />
                        </div>
                        <div className="text-2xl lg:text-5xl text-white font-baslik"><span className='italic'>Ticari koruma </span>için <span className='italic'>hukuki sistematik</span></div>
                        <div className="lg:text-xl font-medium text-[#CCAA60] font-outfit">
                            {/* It is a long established fact that a reader will be distracted by the readable content of a looking at its layout. */ }
                        </div>
                        {/*<a href="#">
                            <button className="px-5 py-3 lg:py-8 lg:px-16 bg-[#D1B06B] font-medium text-xl font-outfit">Get in Touch</button>
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
