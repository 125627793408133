import React from 'react'
import Aboutone from '../assest/aboutusone.png'
import Abouttwo from '../assest/aboutustwo.png'
import Bannericon from '../assest/bannericon.svg'
import Person from '../assest/smallperson.png'
import Icon from '../assest/pappericon.svg'

const Aboutus = () => {
    return (
        <div className="flex flex-col w-4/5 lg:w-full gap-10 py-10 lg:py-28">
            {/* 
            <div className="flex flex-col lg:flex-row w-full justify-center gap-10 lg:gap-28"> 
                <div className="flex justify-center">
                    <img src={Aboutone} alt="" />
                </div>
                <div className="flex flex-col gap-5 w-full lg:w-2/5 text-center lg:text-left justify-center items-center lg:items-start text-white">
                    <div className="text-3xl w-full lg:w-2/3 font-baslik">
                        The Simple Choice <br /> for Complex Litigation
                    </div>
                    <div>
                        <img src={Bannericon} alt="" />
                    </div>
                    <div className="flex font-outfit text-[#D7D7D7]">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius placeat temporibus repudiandae quo tenetur velit, dolorum illo eaque
                        voluptates ab deleniti quidem rem molestiae facilis! Quo aut perferendis accusantium aliquam.
                    </div>
                </div>
            </div>
            */}
            <div className="flex flex-col lg:flex-row w-full justify-center items-start gap-10 lg:gap-28">
                <div className="flex justify-center">
                    <img src={Abouttwo} alt="" />
                </div>
                <div className="flex flex-col gap-10 w-full lg:w-2/5 justify-center text-white">
                {/* 
                    <div className="flex flex-row items-center justify-center lg:justify-start  gap-5">
                        <img src={Person} alt="" />
                        <span className=" font-baslik text-2xl">Av Abdulkadir Erdem</span>
                    </div>
                */}
                    <div className="flex w-full gap-10 font-outfit">
                        <div className="bg-[#3B434E] flex flex-row gap-5 w-full h-[50px] lg:w-full lg:h-[125px] items-center justify-start pl-1 lg:pl-5">
                            <img className="lg:flex hidden" src={Icon} alt="" />
                            <div className="flex flex-col font-outfit lg:text-xl">
                                <div>BUSINESS ADMINISTRATION - MBA GRAUDATE</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full gap-10 font-outfit">
                        <div className="bg-[#D1B06B] flex flex-row gap-2 lg:gap-6 w-full h-[50px] lg:w-full lg:h-[125px] items-center justify-start pl-1 lg:pl-5">
                            <div className="flex bg-[#1F2732] w-[40px] h-[30px] lg:w-[75px] lg:h-[50px] justify-center items-center rounded-sm">
                                <span className="font-outfit lg:text-xl">14+</span>
                            </div>
                            <div className="flex flex-col">
                                <div className="text-[#1F2732] lg:text-xl">
                                    YILLIK KURUMSAL PİYASA TECRÜBESİ
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Aboutus
