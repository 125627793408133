import React from 'react'
import Icon from '../assest/bannericon.svg'
import Caseicon from '../assest/caseicon.svg'
import { Link } from 'react-router-dom'

const Practice = (props:{data: any}) => {
    const dataArray:any = props.data
    return (
        <div className="w-full flex flex-col justify-center items-center bg-[#161D27] py-10 lg:py-28">
            {(dataArray.divisionTitle) ? (
            <div className="w-4/5 flex flex-col lg:flex-row justify-around items-center gap-10 lg:gap-24">
                <div className=" w-2/5 flex flex-col lg:border-r-2 border-[#3A4553] gap-7">
                  
                    <div className="text-3xl lg:text-4xl text-white font-baslik">
                        {dataArray.divisionTitle}
                    </div>
                    <div>
                        <img src={Icon} alt="" />
                    </div>
                </div>
                <div className="lg:w-3/5 text-center lg:text-left font-outfit text-[#D7D7D7]">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo vel aliquid harum, placeat dolorem quo, dolores doloribus laudantium vitae
                    nemo, adipisci consequuntur nulla deleniti provident ullam expedita tenetur quaerat quam!
                </div>
            </div>
            ): null }
            <div className="w-4/5 grid grid-cols-1 md:grid-cols-3 justify-center items-start py-20 gap-10">
            {dataArray.subDivision.map((item: any, i: any ) => (
                <div className="flex flex-col bg-[#1F2732] gap-7 justify-between items-center p-8 min-h-[-webkit-fill-available]" key={i}>
                    <div className="w-full flex flex-col gap-2">
                        {item.subAreaTitle ? <div className="font-baslik text-2xl text-white min-h-14">{item.subAreaTitle}</div> : null}
                        {item.subAreaSubTitle01 ? <div className="text-[#D7D7D7] text-sm pb-3 pt-3"><li>{item.subAreaSubTitle01}</li></div> : null}
                        {item.subAreaSubTitle02 ? <div className="text-[#D7D7D7] text-sm pb-3"><li>{item.subAreaSubTitle02}</li></div> : null}
                        {item.subAreaSubTitle03 ? <div className="text-[#D7D7D7] text-sm pb-3"><li>{item.subAreaSubTitle03}</li></div> : null}
                        {item.subAreaSubTitle04 ? <div className="text-[#D7D7D7] text-sm pb-3"><li>{item.subAreaSubTitle04}</li></div> : null}
                    </div>
                    <div className="w-full items-end justify-end flex flex-col gap-5">
                            <div className="w-full border-t-2 border-[#3A4553]"></div>
                            <div className="w-full flex flex-row items-center justify-around gap-24">
                                <div className="w-1/5">
                                    <img src={Caseicon} alt="" width={30} height={30}/>
                                </div>
                                <Link to={""} className="w-2/5">
                                    <button className="w-full h-[30px] lg:h-[30px] flex bg-[#161D27] text-white items-center justify-center text-sm">Detaylı Bilgi</button>
                                </Link>
                            </div>

                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default Practice
